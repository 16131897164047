<template>
  <ContentFrame>
    <table>
      <tr class="w-[100%] flex gap-10">
        <td>Training Title</td>
        <td>Training Description</td>
        <td>Assign Date</td>
      </tr>
      <tr v-for="training in assignedTrainings" :key="training.title">
        <td>{{ training.title }}</td>
        <td>{{ training.description }}</td>
        <td>{{ training.assignDate }}</td>
      </tr>
    </table>
  </ContentFrame>
</template>
<script>
import ContentFrame from "@/ui_components/ContentFrame.vue";
import useAssignedTraining from "@/components/useAssignedTraining.vue";
import { watch } from "vue";

const assignedTrainings = useAssignedTraining.assignedTrainings;
watch(
  () => assignedTrainings,
  (v) => {
    console.log(v);
  },
);

export default {
  name: "TrainingListPage",
  props: ["assignedTrainings"],
  components: { ContentFrame },
};
</script>
