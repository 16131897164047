<template>
  <div class="flex w-[100%] justify-between">
    <a
      :href="'#' + route"
      v-for="route of Object.keys(routes)"
      :key="route"
      class="rounded p-2 hover:bg-primary hover:bg-opacity-50"
    >
      <div>{{ routes[route][1] }}</div>
    </a>
  </div>
  <component :is="currentView"/>
</template>

<script setup>
import { ref, computed } from "vue";
import DashboardPage from "../pages/DashboardPage.vue";
import LearningPage from "../pages/LearningPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import TrainingListPage from "../pages/TrainingListPage.vue";
import TrainingPage from "../pages/TrainingPage.vue";
import NotFoundPage from "../pages/NotFouldPage.vue";
import LoginPage from "../pages/LoginPage.vue";
import useLogin from "../components/useLogin.vue";

let routes = {
  "/": [DashboardPage, "Dashboard"],
  "/learning": [LearningPage, "Learning"],
  "/training-list": [TrainingListPage, "Training List"],
  "/training": [TrainingPage, "Training"],
  "/login": [LoginPage, "Login"],
  "/profile": [ProfilePage, "Profile"],
};

const currentPath = ref(window.location.hash);
const loginData = useLogin.loginData;
window.addEventListener("hashchange", () => {
  currentPath.value = window.location.hash;

});

const currentView = computed(() => {
  if (!loginData.value) {
    return LoginPage;
  }
  return routes[currentPath.value.slice(1) || "/"][0] || NotFoundPage;
});
</script>
