<template>
  <ContentFrame>
    <h1>Training Page</h1>
    <div class="grid h-[100%] w-[100%] grid-cols-4 gap-10 overflow-hidden p-10">
      <TrainingCard
        v-for="training in trainingData()"
        :key="training.title"
        :title="training.title"
        :description="training.description"
        :training-id="training.id"
      />
    </div>
  </ContentFrame>
</template>
<script>
import ContentFrame from "@/ui_components/ContentFrame.vue";
import TrainingCard from "@/ui_components/TrainingCard.vue";
import trainingData from "@/data/Trainings";

export default {
  name: "TrainingPage",
  methods: {
    trainingData() {
      return trainingData;
    },
  },
  components: { TrainingCard, ContentFrame },
};
</script>
