<template>
  <JCalendar/>
</template>
<script>

import JCalendar from "@/ui_components/JCalendar.vue";

export default {
  name: 'DashboardPage',
  components: { JCalendar }

}
</script>