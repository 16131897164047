<template>
  <component
    :is="props.type"
    :disabled="props.disabled"
    :variant="props.variant"
    :size="props.size"
    :class="[baseButtonStyle, colorVariants, loadingStyle]"
    @click="handleClick"
  >
    <slot v-if="!loading"></slot>
    <div
      v-else
      class="h-4 w-4 animate-spin rounded-full border-2 border-[inherit] border-b-transparent"
    />
  </component>
</template>

<script setup lang="ts">
import { defineProps, computed, PropType, ref } from "vue";

type ButtonType = "filled" | "outlined";

const props = defineProps({
  type: { type: String, default: "button" },
  disabled: { type: Boolean, default: false },
  variant: { type: String as PropType<ButtonType> },
  size: { type: String, default: "normal" },
});

const loading = ref(false);

const handleClick = () => {
  loading.value = true;
};

const baseButtonStyle =
  "text-xs font-bold uppercase tracking-wider py-2 px-3 rounded-full inline-flex items-center justify-center relative cursor-pointer w-min-32";

const colorVariants = computed(() => {
  switch (props.variant) {
    case "filled":
      return "text-white bg-primary hover:bg-opacity-80";
    case "outlined":
      return "border-2 !border-primary text-primary hover:shadow-l";
    default:
      return baseButtonStyle;
  }
});

//TODO ITT TARTOTTAM A LOADING HOZZÁADÁSÁNÁL
const loadingStyle = computed(() => {
  if (loading.value && props.variant === "filled") {
    return "border-white absolute inline-flex items-center";
  }
  if (loading.value && props.variant === "outlined") {
    return "border-primary absolute inline-flex items-center";
  }
  return "border-white absolute inline-flex items-center";
});
</script>

<style scoped></style>
