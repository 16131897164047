<template>
  <div
    class="flex w-3/4 max-w-lg flex-col items-center justify-center overflow-hidden rounded-2xl bg-blue-50"
  >
    <img src="@/assets/KR.png" alt="logo" class="h-56 w-full object-cover" />
    <div class="flex flex-col justify-center pb-4 pl-6 pr-6 pt-4">
      <p>Felhasználónév</p>
      <input
        v-model="email"
        type="email"
        class="mb-2 rounded border-2 border-primary"
      />
      <p>Jelszó</p>
      <input
        v-model="password"
        type="password"
        class="mb-4 rounded border-2 border-primary"
      />
      <custom-button :onClick="handleClick" variant="filled" type="submit">
        Bejelentkezés
      </custom-button>
    </div>
    <div class="flex justify-evenly">
      <p>Még nincs fiókod?</p>
      <custom-button variant="outlined" class="w-32">
        Regisztráció
      </custom-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import CustomButton from "@/ui_components/customButton.vue";
import { ref } from "vue";
import useLogin from "../components/useLogin.vue";

const email = ref("");
const password = ref("");

const handleClick = () => {
  useLogin.login({ email: email.value, password: password.value });
};
</script>
