<template>
  <AppFrame>
    <JRouter />
  </AppFrame>
</template>
<script>
import { defineComponent } from "vue";
import JRouter from "./ui_components/JRouter.vue";
import AppFrame from "@/ui_components/AppFrame.vue";


export default defineComponent({
  name: "App",
  props: ["loginData"],
  components: {
    AppFrame,
    JRouter,
  },
});
</script>
