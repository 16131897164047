<template>
  <ContentFrame>
    <div>Page NOT FOUND</div>
  </ContentFrame>
</template>
<script>
import ContentFrame from "@/ui_components/ContentFrame.vue";

export default {
  name: "NotFoundPage",
  components: { ContentFrame },
};
</script>
