<template>
  <div
    class="h-32 rounded-3xl border-2 bg-amber-100 p-3"
    @click="openTrainingDialog"
  >
    <h1>{{ props.title }}</h1>
    <p>{{ props.description }}</p>
  </div>
  <TrainingDialog :trainingId="props.trainingId" @closeSelf="closeSelf" v-if="isDialogOpened" />
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";
import TrainingDialog from "@/ui_components/TrainingDialog.vue";

const isDialogOpened = ref(false);
const openTrainingDialog = () => {
  isDialogOpened.value = true;
};

const closeSelf = () => {
  isDialogOpened.value = false;
};

const props = defineProps(["title", "description", "image", "trainingId"]);
</script>
