<script>
import { ref } from "vue";
import AppConfig from "@/components/AppConfig";

const loginData = ref(null);

const login = (value) => {
  const authToken = "Basic " + window.btoa(value.email + ":" + value.password);
  const url = AppConfig.BACKEND_URL + "/user";
  fetch(url, {
    method: "GET",
    headers: {
      "Authorization": authToken,
    },
  })
    .then(async (response) => {
      console.log(response)
      if (response.status === 200) {
        const jwtToken = response.header("Authorization");
        if (jwtToken) {
          loginData.value = {
            jwtToken: jwtToken,
            userName: await response.json(),
          };
          return;
        }
        console.log("Login Failed");
      }
    })
};

export default { loginData, login };
</script>
