<template>
  <div
    class="absolute left-0 top-0 h-[100%] w-[100%] bg-gray-800 opacity-20"
    @click="closeSelf"
  ></div>
  <div class="absolute left-1/3 z-10 h-1/2 w-1/3 bg-white p-6">
    <h1>Title</h1>
    <button @click="assignTraining">Assign to me</button>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from "vue";
import useAssignedTraining from "@/components/useAssignedTraining.vue";

const emit = defineEmits(["closeSelf"]);
const props = defineProps(["trainingId"]);

const closeSelf = () => {
  emit("closeSelf");
};
const assignTraining = () => {
  useAssignedTraining.assignTraining(props.trainingId)
};
</script>
