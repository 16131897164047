<script>
import { ref } from "vue";
import Trainings from "@/data/Trainings";
const assignedTrainings = ref([]);

const assignTraining = (trainingId) => {
  let training = Trainings.filter((t) => t.id === trainingId)[0]
  training.assignDate = new Date(Date.now()).toDateString()
  assignedTrainings.value.push(training);
};

export default { assignedTrainings, assignTraining };
</script>
