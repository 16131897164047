<template>
  <ContentFrame>
    <div>Profile Page</div>
    <div class="flex flex-col">
      <div>
        <v-icon name="bi-music-player" />
      </div>
      <div></div>
    </div>
  </ContentFrame>
</template>
<script>
import ContentFrame from "@/ui_components/ContentFrame.vue";

export default {
  name: "ProfilePage",
  components: {  ContentFrame },
};
</script>