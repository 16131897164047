<template>
  <VCalendar :attributes="attributes"/>
</template>

<script setup lang="ts">

import { ref } from "vue";
import { Calendar as VCalendar } from "v-calendar";
const attributes = ref([
  {
    key: 'today',
    highlight: true,
    dates: new Date(),
  },
]);
</script>
