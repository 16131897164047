<template>
  <ContentFrame>
    <div>Learning Page</div>
  </ContentFrame>
</template>
<script>
import ContentFrame from "@/ui_components/ContentFrame.vue";

export default {
  name: 'LearningPage',
  components: { ContentFrame }
}
</script>